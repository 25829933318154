import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import {HttpParams} from "@angular/common/http";
import { SharedService } from "../../shared/service/shared.service";
import { environment } from '../../../../environments/environment';

@Injectable()
export class SearchService {
	
	/**define valiables */
	SITE_API_URL		:	any		=	'';
	SITE_URL			:	any		=	'';
	data				:	any		=	{};

	/**define constructor */
	constructor(private httpClient:HttpClient,public SharedService : SharedService) {
		this.SITE_API_URL		=	environment.API_URL;
		this.SITE_URL			=	environment.SITE_URL;
	}
	
	/**This function are used for  get the basic search data*/
	getBasicSerachData(slug:any){
		return this.SharedService.getRequest(this.SITE_API_URL+'basic-search-data/'+slug);
	}
	
	getSvaedSerachData(slug:any){
		return this.SharedService.getRequest(this.SITE_API_URL+'get-saved-search-data/'+slug);
		
	}
	
	deleteSaveSerach(id:any){
		return this.SharedService.getRequest(this.SITE_API_URL+'delete-save-search-data/'+id);
		
	}
	
	/** Function to submit basic serach form */
	submitBasicSearchForm(submitBasicSerachForm:any) {
		return this.SharedService.getPost(this.SITE_API_URL+'submit-basic-search-data',submitBasicSerachForm);
	}
	
	/** Function to submit quick serach form */
	submitQuickSearchForm(submitQuickSerachForm:any) {
		return this.SharedService.getPost(this.SITE_API_URL+'submit-quick-search-data',submitQuickSerachForm);
	}
	
	/** Function to get basic serach form selected*/
	getBasicSerachDataSeleted(id:any): Promise<any>{
		return this.SharedService.getRequest(this.SITE_API_URL+'get-save-search-data/'+id);
	}
	
	/** Function to submit the profile serach form */
	submitSearchFormForProfileId(submitSearchFormProfileIdData:any){
		return this.SharedService.getPost(this.SITE_API_URL+'dashboard-submit-left-search-data',submitSearchFormProfileIdData);
	}
	
	/**This function are used get the quick search form */
	getQuickSerachForm(){
		return this.SharedService.getRequest(this.SITE_API_URL+'get-quick-search-data');
	}
	
	/**This function are sed for get the state data */
	getStateData(country_id:any){
		return this.SharedService.getRequest(this.SITE_API_URL+'get-residence-state-data/'+country_id);
	}
	
	
	//handler function added 
    private handleError (error: Response | any) {
        console.error(error.message || error);
        return Promise.reject(error.message || error);
    }
}
  
  
